import React, { useState } from 'react';
import { InputField, InputFieldLengthCounter } from '../../atoms';
import './inputfieldwrapper.scss';
const InputFieldWrapper = ({
  handleBlur,
  handleChange,
  maxLength,
  placeHolder,
  value,
  name,
  showCount,
  inputFieldclassName,
  handleFocus,
  type,
  autoFocus,
}) => {
  const [focus, setFocus] = useState(false);
  const handleFocuss = () => {
    setFocus(true);
  };
  const handleBlurr = (e) => {
    setFocus(false);
    handleBlur(e);
  };
  return (
    <div className={focus ? 'InputFieldWrapper focus' : 'InputFieldWrapper'}>
      <InputField
        maxLength={maxLength}
        handleBlur={(e) => handleBlurr(e)}
        handleChange={handleChange}
        value={value}
        name={name}
        placeHolder={placeHolder}
        inputFieldclassName={inputFieldclassName}
        handleFocus={handleFocuss}
        type={type}
        autoFocus={autoFocus}
      />
      {showCount ? (
        <InputFieldLengthCounter
          className={
            value
              ? maxLength - value.length < 10
                ? 'Inputfieldlengthcounter error'
                : `Inputfieldlengthcounter`
              : 'Inputfieldlengthcounter'
          }
        >
          {value ? maxLength - value?.length : maxLength}
        </InputFieldLengthCounter>
      ) : (
        <InputFieldLengthCounter className='Inputfieldlengthcounter'>
          Hours
        </InputFieldLengthCounter>
      )}
    </div>
  );
};
InputFieldWrapper.defaultProps = {
  showCount: true,
};
export default InputFieldWrapper;
