import React from 'react';
import './button.scss';

const Button = ({ className, handleClick, isDisabled, children, value }) => {
  return (
    <button
      className={`Button ${className}`}
      onClick={handleClick}
      value={value}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};
Button.defaultProps = {
  className: 'w-full',
  handleClick: () => {},
  isDisabled: false,
  children: 'Default',
  value: '',
};

export default Button;
