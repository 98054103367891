const deletePollQuery = `

mutation ($token: String!, $id: String!) {
    mutationWithAuth(token:$token) {
     update{
       poll(id:$id){
         delete
       }
     }
   }
 }

`;

export default deletePollQuery;
