/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import * as Molecules from '../../molecules';
import * as Atoms from '../../atoms';
import { getAxios } from '../../../service/axios';
import URL_OBJECT from '../../../service/config';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import './index.scss';
const style = {
  height: 30,
  margin: '20px 0',
  padding: '0 20px',
};

const SelectCourse = (props) => {
  console.log(props?.location?.state);
  let history = useHistory();
  const deselectedCheckbox = useRef([]);
  const doneMappingPropsOnFirstMount = useRef(false);
  // eslint-disable-next-line
  const [err, setError] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [maxCount, setMaxCount] = useState(0);
  const [renderList, setRenderList] = useState([]);
  const offset = useRef(0);
  const isSearchDirty = useRef(false);
  const [showloader, setShowLoader] = useState(true);
  const [SELECT_ALL_COURSE_VALUES, SET_SELECT_ALL_COURSE_VALUES] =
    useState(false);
  const [search, setSearch] = useState('');

  const handleSelect = () => {
    if (props.type === 'BATCH') {
      sessionStorage.setItem('IS_BATCHES_REMOVED', false);
    } else {
      sessionStorage.setItem('IS_COURSES_REMOVED', false);
    }
    history.push({
      pathname: '/createpolls',

      state: {
        formValues: props?.location?.state?.formValues,
        selectedCheckboxForCourses:
          props.type === 'COURSE'
            ? selectedCheckbox
            : props?.location?.state?.selectedCheckboxForCourses,
        selectedCheckboxForBatches:
          props.type === 'BATCH'
            ? selectedCheckbox
            : props?.location?.state?.selectedCheckboxForBatches,
        appDownloadText: props?.location?.state?.permanentAppDownloadText,
        SELECT_ALL_COURSE_VALUES:
          props.type === 'COURSE'
            ? SELECT_ALL_COURSE_VALUES
            : props?.location?.state?.SELECT_ALL_COURSE_VALUES,
        SELECT_ALL_BATCH_VALUES:
          props.type === 'BATCH'
            ? SELECT_ALL_COURSE_VALUES
            : props?.location?.state?.SELECT_ALL_BATCH_VALUES,
        unselectedCheckboxListForBatches:
          props.type === 'BATCH'
            ? deselectedCheckbox.current
            : props?.location?.state?.unselectedCheckboxListForBatches,
        unselectedCheckboxListForCourses:
          props.type === 'COURSE'
            ? deselectedCheckbox.current
            : props?.location?.state?.unselectedCheckboxListForCourses,
      },
    });
  };
  const handleBlur = (e) => {};
  const handleFocus = (e) => {};

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  const getDetails = () => {
    getAxios(
      URL_OBJECT.API_BASE_URL_FILTER.replace(
        '[TYPEID=typeId]',
        `typeId=${props.typeId}`
      )
        .replace('[LIMIT=limit]', `limit=${20}`)
        .replace('[OFFSET=offset]', `offset=${offset.current}`)
        .replace('[SEARCH=search]', `search=${search}`)
    )
      .then((res) => {
        setMaxCount(res.data.list[0].count);

        let data = [...res.data.list[0].list];

        let a = [...selectedCheckbox];
        if (!doneMappingPropsOnFirstMount.current) {
          if (props.type === 'BATCH') {
            props?.history?.location?.state?.selectedCheckboxForBatches?.map(
              (e, i) => {
                if (!a.includes(e)) {
                  a.push(e.toString());
                }
              }
            );

            if (props?.location?.state?.unselectedCheckboxListForBatches) {
              console.log(
                props?.location?.state?.unselectedCheckboxListForBatches
              );
            }
            if (props?.location?.state?.SELECT_ALL_BATCH_VALUES) {
              data.map((r) => {
                // a.push(r.id.toString());
                if (!a.includes(r.id.toString())) {
                  a.push(r.id.toString());
                }
              });

              if (
                props?.location?.state?.tempunselectedCheckboxListForBatches
              ) {
                a = a.filter(
                  (x) =>
                    !props?.location?.state?.tempunselectedCheckboxListForBatches.includes(
                      x
                    )
                );
                deselectedCheckbox.current = [
                  ...props?.location?.state
                    ?.tempunselectedCheckboxListForBatches,
                ];
                //  deselectedCheckbox.current([...props?.location?.state?.unselectedCheckboxListForBatches])
                // a=a.filter(id=>id!==props?.location?.state?.unselectedCheckboxListForBatches.includes(id))
              }
              console.log(a);
              setSelectedCheckbox([...a]);
              SET_SELECT_ALL_COURSE_VALUES(true);
            }
          } else {
            props?.history?.location?.state?.selectedCheckboxForCourses?.map(
              (e, i) => {
                if (!a.includes(e)) {
                  a.push(e.toString());
                }
              }
            );

            if (props?.history?.location?.state?.SELECT_ALL_COURSE_VALUES) {
              data.map((d) => {
                if (!a.includes(d.id.toString())) {
                  a.push(d.id.toString());
                }
              });
              SET_SELECT_ALL_COURSE_VALUES(true);

              if (
                props?.location?.state?.tempunselectedCheckboxListForCourses
              ) {
                a = a.filter(
                  (x) =>
                    !props?.location?.state?.tempunselectedCheckboxListForCourses.includes(
                      x
                    )
                );
                deselectedCheckbox.current = [
                  ...props?.location?.state
                    ?.tempunselectedCheckboxListForCourses,
                ];
                // a=a.filter(id=>id!==props?.location?.state?.unselectedCheckboxListForBatches.includes(id))
              }
              setSelectedCheckbox([...a]);
            }
          }

          doneMappingPropsOnFirstMount.current = true;
        }

        if (!props?.location?.state?.SELECT_ALL_BATCH_VALUES) {
          setSelectedCheckbox([...a]);
        }
        if (!props?.location?.state?.SELECT_ALL_COURSE_VALUES) {
          setSelectedCheckbox([...a]);
        }

        if (SELECT_ALL_COURSE_VALUES) {
          let a = [...selectedCheckbox];
          data.map((d) => a.push(d.id.toString()));
          setSelectedCheckbox([...a]);
        }

        setRenderList(offset.current === 0 ? data : [...renderList, ...data]);

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        setError(true);
      });
  };
  useEffect(() => {
    // here clear the course value sand set offest=0
    if (search) {
      offset.current = 0;
    }
    if (!search && isSearchDirty.current) {
      offset.current = 0;
      getDetails();
    }
    if (search && offset.current === 0) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {}, [selectedCheckbox]);
  useEffect(() => {}, [renderList]);
  const handleChange = (e, type) => {
    let { checked, value } = e.target;

    switch (type) {
      case 'COURSE_VALUES':
        let a = [...selectedCheckbox];
        let index = a.indexOf(value);
        if (checked) {
          if (index === -1) {
            a.push(value);

            deselectedCheckbox.current = deselectedCheckbox?.current?.filter(
              (id) => id !== value
            );
          } else {
            a.splice(index);
          }
        } else {
          a = a.filter((id) => id !== value);

          deselectedCheckbox.current.push(value);
          // unselectedCheckbox.push(value);
          // selectedCheckbox=selectedCheckbox?.filter(id=>id===value);
        }
        setSelectedCheckbox([...a]);

        break;

      case 'SELECT_ALL_COURSE_VALUES':
        if (checked) {
          let a = [...selectedCheckbox];
          if (!selectedCheckbox.includes(value)) {
            renderList.map((r) => {
              a.push(r.id.toString());
            });
          }
          deselectedCheckbox.current = [];
          setSelectedCheckbox([...a]);
        } else {
          setSelectedCheckbox([]);
        }
        SET_SELECT_ALL_COURSE_VALUES(checked);
        setSearch('');
        break;
      case 'SEARCH_BATCHES':
        setSearch(value);
        SET_SELECT_ALL_COURSE_VALUES(false);
        sessionStorage.setItem(props.sessionConstant, false);
        if (!value) {
          isSearchDirty.current = true;
        }
        break;

      default:
        break;
    }
  };
  const checkIfButtonisDisabled = () => {
    if (selectedCheckbox.length > 0) return false;
    return true;
  };

  const fetchData = () => {
    offset.current = offset.current + 20;
    getDetails();
  };
  const hasMore = () => {
    if (maxCount === renderList.length) return false;
    return true;
  };
  console.log(deselectedCheckbox.current, selectedCheckbox);
  return (
    <>
      <Molecules.NavBar
        navBar={props.navBar}
        data={{
          formValues: props?.history?.location?.state?.formValues,
          courseValues:
            props.type === 'COURSE'
              ? selectedCheckbox
              : props?.history?.location?.state?.selectedCheckboxForCourses,
          batchValues:
            props.type === 'BATCH'
              ? selectedCheckbox
              : props?.history?.location?.state?.selectedCheckboxForBatches,
          appDownloadText:
            props?.history?.location?.state?.permanentAppDownloadText,
          initialSavedBatchValues:
            props?.history?.location?.state?.initialSavedBatchValues,
          initialSavedCourseValues:
            props?.history?.location?.state?.initialSavedCourseValues,
          initialSavedAppDownloadText:
            props?.history?.location?.state?.initialSavedAppDownloadText,
          SELECT_ALL_COURSE_VALUES:
            props.type === 'COURSE' &&
            props?.location?.state?.SELECT_ALL_COURSE_VALUES,
          SELECT_ALL_BATCH_VALUES:
            props.type === 'BATCH' &&
            props?.location?.state?.SELECT_ALL_BATCH_VALUES,
          unselectedCheckboxListForBatches:
            props?.location?.state?.unselectedCheckboxListForBatches,
          unselectedCheckboxListForCourses:
            props?.location?.state?.unselectedCheckboxListForCourses,
          tempunselectedCheckboxListForBatches:
            props.type === 'BATCH'
              ? deselectedCheckbox.current
              : props?.location?.state?.unselectedCheckboxListForBatches,
          tempunselectedCheckboxListForCourses:
            props.type === 'COURSE'
              ? deselectedCheckbox.current
              : props?.location?.state?.unselectedCheckboxListForCourses,
        }}
      />
      {showloader ? (
        <Molecules.LoaderWrapper />
      ) : err ? (
        <div className='errorDiv'>Please try again later after some time</div>
      ) : (
        <div className='Select__MiddleSection'>
          {SELECT_ALL_COURSE_VALUES ? null : (
            <div className=''>
              <Molecules.SearchBox
                handleBlur={handleBlur}
                handleChange={(e) => handleChange(e, 'SEARCH_BATCHES')}
                name={'search'}
                value={search}
                placeHolder={'Search'}
                handleFocus={handleFocus}
              />
            </div>
          )}
          {search ? null : (
            <div className='SelectAll'>
              <div className='SelectAll__Text'>Batch</div>
              <div className='SelectAll__CheckBoxContainer'>
                <Atoms.CheckboxButton
                  name='SELECT_ALL_COURSE_VALUES'
                  value='a'
                  handleChange={(e) =>
                    handleChange(e, 'SELECT_ALL_COURSE_VALUES')
                  }
                  label='Select All'
                  type='SELECT_ALL_COURSE_VALUES'
                  checked={SELECT_ALL_COURSE_VALUES}
                />
              </div>
            </div>
          )}
          <div
            className={
              search || SELECT_ALL_COURSE_VALUES ? 'scroll more' : 'scroll'
            }
          >
            {/* <div style={{height:'20px'}}></div> */}
            {renderList.length > 0 ? (
              <InfiniteScroll
                dataLength={renderList.length}
                next={() => {
                  fetchData();
                }}
                hasMore={hasMore()}
                loader={<Atoms.Loader />}
                // className={search || SELECT_ALL_COURSE_VALUES ? `large` : `small`}
                height={
                  search || SELECT_ALL_COURSE_VALUES
                    ? `calc(100vh - 150px)`
                    : `calc(97vh - 230px)`
                }
              >
                {renderList.map((courseValue, index) => (
                  <div style={style} key={index}>
                    <Atoms.CheckboxButton
                      name={courseValue.name}
                      value={courseValue.id}
                      label={courseValue.name}
                      checked={selectedCheckbox.includes(
                        courseValue.id.toString()
                      )}
                      handleChange={(e) => handleChange(e, 'COURSE_VALUES')}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div
                style={{
                  display: 'flex',
                  padding: '20% 4%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Nothing to show!
              </div>
            )}
          </div>
          <div style={{ height: '20px' }}></div>
          {/* <MyApp handleChange={handleChange}/> */}
          <div style={{ height: '20px' }}></div>

          <div
            className='BC_Selection_Wrapper bottom'
            style={{
              width: document.querySelector('.Navbar')?.clientWidth + 'px',
            }}
          >
            <Atoms.Button
              handleClick={() => handleSelect()}
              isDisabled={checkIfButtonisDisabled()}
            >
              Select
            </Atoms.Button>
          </div>
        </div>
      )}{' '}
    </>
  );
};

export default SelectCourse;
