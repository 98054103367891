import React from 'react';
import './inputfield.scss';
const InputField = ({
  handleBlur,
  handleChange,
  maxLength,
  value,
  name,
  placeHolder,
  inputFieldclassName,
  handleFocus,
  type,
  autoFocus,
}) => {
  // const handleChange=(e)=>{
  //
  // }
  return (
    <input
      type={type}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      maxLength={maxLength}
      value={value}
      name={name}
      placeholder={placeHolder}
      className={inputFieldclassName ? inputFieldclassName : 'input-field'}
      autoFocus={autoFocus}
    />
  );
};
InputField.defaultProps = {
  handleBlur: () => {},
  handleChange: () => {},
  maxlength: '40',
  value: '',
  onfocus: () => {},
  type: 'text',
  autoFocus: false,
};

export default InputField;
