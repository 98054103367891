import React from 'react';
import ReactDOM from 'react-dom';
import './modal.scss';

const Modal = (props) => {
  console.log(props);
  if (props.showModal) {
    return ReactDOM.createPortal(
      <div className='Overlay'>
        <div className={props.showModal ? `Modal open ${props.name}` : 'Modal'}>
          {props.children}
        </div>
      </div>,
      document.querySelector('#modal-root')
    );
  } else return null;
};

export default Modal;
