/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import './singlepollstatistics.scss';
import * as Molecules from '../../molecules';
import * as Atoms from '../../atoms';
import { postAxios } from '../../../service/axios';
import { deletePollFormatter } from '../../../service/apiFormatter';
import deletePollQuery from '../../../graphql/deletePollQuery';
import URL_OBJECT from '../../../service/config';

const SinglePollStatistics = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isAllowedToDelete, setisAllowedToDelete] = useState(true);
  const deletePoll = () => {
    let variables = {};
    variables.id = props?.location?.state?.id;
    postAxios(
      URL_OBJECT.API_GRAPH_URL,
      deletePollFormatter(deletePollQuery, variables)
    )
      .then((res) => {
        console.log(res);
        props.history.push('/yourpolls');
      })
      .catch((err) => {
        console.log(err);
        if (err.message === 'Unauthorised access') {
          setisAllowedToDelete(false);
          setShowModal(true);
        }
      });
  };
  const openDeleteModal = () => {
    setShowModal(true);
  };

  const isHighest = (currentValue) => {
    let valueObj = {};
    let sum = 0;

    props?.location?.state?.choices.map((c, i) => {
      valueObj[i] = c.voteInPercentage;
      sum = sum + c.voteInPercentage;
    });

    const getMax = (valueObj) => {
      return Object.keys(valueObj).filter((x) => {
        return valueObj[x] == Math.max.apply(null, Object.values(valueObj));
      });
    };

    if (sum === 0) {
      return { bool: false, index: null };
    } else {
      let arr = getMax(valueObj);
      if (arr.length > 1) {
        return { bool: false, index: null };
      } else {
        return { bool: true, index: arr[0] };
      }
    }
  };

  return (
    <div>
      <Molecules.NavBar
        navBar={props.navBar}
        onDelete={openDeleteModal}
        delete={true}
      />
      <div className='SinglPollStaticstics'>
        <Atoms.CompletedCard data={props?.location?.state} name={'noshadow'} />
        <div className='StatisticsCard'>
          {props?.location?.state?.choices.map((c, i) => (
            <div className='StatisticsCard__element'>
              <div className='StatisticsCard__element__text'>
                <div
                  className={
                    isHighest(c.voteInPercentage).index == i
                      ? `StatisticsCard__element__text__graph show highest`
                      : `StatisticsCard__element__text__graph show`
                  }
                  style={{ width: c.voteInPercentage + '%' }}
                >
                  <div className='StatisticsCard__element__text--text'>
                    {c.choice}{' '}
                  </div>
                </div>
              </div>
              <div className='StatisticsCard__element__completed'>
                {c.voteInPercentage + '%'}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Molecules.Modal showModal={showModal} name={'margin'}>
        {
          <Molecules.ModalWarningandDelete
            isDelete
            setShowModal={setShowModal}
            onDelete={deletePoll}
            isAllowedToDelete={isAllowedToDelete}
            setisAllowedToDelete={setisAllowedToDelete}
          />
        }
      </Molecules.Modal>
    </div>
  );
};

export default SinglePollStatistics;
