import React, { useEffect } from 'react';
import './navbar.scss';
import LeftArrow from '../../../assests/images/arrow-left.svg';
import Trash from '../../../assests/images/trash.svg';
import { useHistory } from 'react-router-dom';
const NavBar = ({ navBar, onDelete, data, closeWindow }) => {
  console.log(data);
  const history = useHistory();
  useEffect(() => {
    window.onMobileUpdate = (type) => {
      handleBack();
    };
  }, [data]);
  const handleBack = () => {
    if (closeWindow) {
      window?.mobile?.setTestState(``);
      window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');

      // window?.mobile?.onDeeplinkExecuted(``);
    }
    // history.push(`${navBar.toPath}`);
    // if(`${navBar.toPath}`===''||`${navBar.toPath}`===''||`${navBar.toPath}`===''){

    // }
    history.push({
      pathname: `${navBar.toPath}`,
      state: data,
    });
  };
  return (
    <>
      {' '}
      <div className='Navbar'>
        <div className='Navbar--back' onClick={handleBack}>
          <img src={LeftArrow} alt='ar' />
        </div>
        <div className='Navbar__Heading'>{navBar.text}</div>
        {navBar.text === 'Poll Details' && (
          <div className='Navbar--Delete'>
            <img src={Trash} alt='d' onClick={onDelete} />
          </div>
        )}
      </div>
    </>
  );
};

NavBar.defaultProps = {
  navBar: {
    text: 'Poll Details',
    currentPath: '/polldetails',
    toPath: '/yourpolls',
  },
  onDelete: () => {},
};
export default NavBar;
