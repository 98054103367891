import React from 'react';
import './completedcard.scss';

const CompleteCard = ({ onClick, data, name }) => {
  return (
    <div className={`CompletedCard ${name}`} onClick={onClick}>
      <div className='CompletedCard__part--above'>{data.name}</div>
      <hr className='CompletedCard__hr' />
      <div className='CompletedCard__part--below'>
        <div className='CompletedCard__part--below__responded'>
          {data.totalVotes} Responded
        </div>
        {data.isExpired ? (
          <div className={'CompletedCard__part--below__Time completed'}>
            <li>{data.pollLengthText}</li>
          </div>
        ) : (
          <div className={'CompletedCard__part--below__Time remaining'}>
            <li>{data.pollLengthText} </li>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompleteCard;
