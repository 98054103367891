import React from 'react';
import './chip.scss';
import XW from '../../../assests/images/xwh.svg';
const Chip = ({ onClick, text }) => {
  return (
    <div className='Chip'>
      <div className='Chip__Text'>{text}</div>
      <div className='Chip__Delete'>
        <img src={XW} alt='cl' onClick={onClick} />
      </div>
    </div>
  );
};

export default Chip;
