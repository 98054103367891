import React from 'react';
import { useHistory } from 'react-router-dom';
import './coursecard.scss';

const CourseCard = ({
  children,
  heading,
  showViewAll,
  path,
  pathStateValues,
}) => {
  let history = useHistory();
  console.log(pathStateValues);
  return (
    <div className='CourseCard'>
      <div className='CourseCard__Heading'>
        <div className='CourseCard__Heading--left'>{heading}</div>
        {showViewAll ? (
          <div
            className='CourseCard__Heading--right'
            onClick={() => {
              history.push({
                pathname: `${path}`,
                state: {
                  formValues: pathStateValues?.formValues,
                  selectedCheckboxForCourses:
                    pathStateValues?.permanentselectedCheckboxForCourses,
                  selectedCheckboxForBatches:
                    pathStateValues?.permanentselectedCheckboxForBatches,
                  permanentAppDownloadText:
                    pathStateValues?.permanentAppDownloadText,
                  initialSavedAppDownloadText:
                    pathStateValues?.initialSavedAppDownloadText,
                  initialSavedCourseValues:
                    pathStateValues?.initialSavedCourseValues,
                  initialSavedBatchValues:
                    pathStateValues?.initialSavedBatchValues,
                  SELECT_ALL_COURSE_VALUES:
                    pathStateValues?.SELECT_ALL_COURSE_VALUES,
                  SELECT_ALL_BATCH_VALUES:
                    pathStateValues?.SELECT_ALL_BATCH_VALUES,
                  unselectedCheckboxListForBatches:
                    pathStateValues?.unselectedCheckboxListForBatches,
                  unselectedCheckboxListForCourses:
                    pathStateValues?.unselectedCheckboxListForCourses,
                  tempunselectedCheckboxListForBatches:
                    pathStateValues?.tempunselectedCheckboxListForBatches,
                  tempunselectedCheckboxListForCourses:
                    pathStateValues?.tempunselectedCheckboxListForCourses,
                },
              });
              // history.push(`${path}`)
            }}
          >
            VIEW ALL
          </div>
        ) : null}
      </div>
      <div className='CourseCard__Body'>{children}</div>
    </div>
  );
};

export default CourseCard;
