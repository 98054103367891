import React, { useState, useEffect, useRef } from 'react';
import * as Molecules from '../../molecules';
import * as Atoms from '../../atoms';
import GraphPoll from '../../../assests/images/pollgraph.png';
import PlusButton from '../../../assests/images/plusbutton.png';
import './yourpolls.scss';
import { useHistory } from 'react-router-dom';
import { postAxios } from '../../../service/axios';
import URL_OBJECT from '../../../service/config';
import getPollDetails from '../../../graphql/getPollDetails';
import { getPollDetailsFormatter } from '../../../service/apiFormatter';
import { getURLPARAMS } from '../../../utils/helper';
const YourPolls = (props) => {
  // eslint-disable-next-line
  const [polls, setPolls] = useState({});
  const [apiFetching, setApiFetching] = useState(true);
  const [isError, setError] = useState(false);
  const activePolls = useRef(0);
  const [showModal, setShowModal] = useState(false);
  let history = useHistory();
  const handleClick = () => {
    if (activePolls.current < 3) {
      props.history.push('/createpolls');
    } else {
      setShowModal(true);
    }
  };
  useEffect(() => {
    postAxios(URL_OBJECT.API_GRAPH_URL, getPollDetailsFormatter(getPollDetails))
      .then((res) => {
        // setPolls([...res?.data?.withAuth?.user?.polls])
        setPolls({ ...res });

        setApiFetching(false);

        res?.withAuth?.user?.polls?.forEach((e) => {
          if (e.isExpired === false) {
            activePolls.current++;
          }
        });
      })
      .catch((err) => {
        setError(true);
        setApiFetching(false);
      });
    sessionStorage.setItem('IS_COURSES_REMOVED', false);
    sessionStorage.setItem('IS_BATCHES_REMOVED', false);
    sessionStorage.setItem('IS_APP_DOWNLOADS_REMOVED', false);
    sessionStorage.setItem('IS_INITIAL_APP_DOWNLOAD', true);
    if (getURLPARAMS().token) {
      sessionStorage.setItem('token', JSON.stringify(getURLPARAMS().token));
    }
  }, []);
  console.log(!(polls && polls.poll_total), polls?.poll_total);

  console.log(polls);
  return (
    <>
      <>
        <Molecules.NavBar navBar={props.navBar} closeWindow />
        {apiFetching ? (
          <Molecules.LoaderWrapper />
        ) : isError ? (
          <div className='errorDiv'>Please try again later after some time</div>
        ) : (
          <>
            {!(polls && polls.poll_total) > 0 ? (
              <>
                <div className='YourPoll empty'>
                  <div className='YourPoll empty__imgDiv'>
                    <img src={GraphPoll} alt='graph' />
                    <div className='YourPoll empty__text'>
                      <p>Create your first poll</p>
                      <p className='YourPoll empty__text__p2'>
                        Send polls to your students and get feedback
                      </p>
                    </div>
                  </div>
                  <Atoms.Button
                    className='w-half'
                    handleClick={() => handleClick()}
                  >
                    Create Poll
                  </Atoms.Button>
                </div>
              </>
            ) : (
              <>
                <div className='YourPoll present'>
                  <div className='YourPoll__Heading'>
                    All Polls ({polls?.poll_total})
                  </div>

                  {polls?.withAuth?.user?.polls?.map((poll, i) => (
                    <Atoms.CompletedCard
                      onClick={() =>
                        history.push({
                          pathname: '/polldetails',
                          state: poll,
                        })
                      }
                      data={poll}
                      key={`sdio${i}90`}
                      name={'margin'}
                    />
                  ))}

                  <div className='YourPoll__ButtonWrapper'>
                    <img
                      src={PlusButton}
                      alt='a'
                      className='YourPoll__ButtonWrapper__addButton'
                      onClick={() => {
                        handleClick();
                      }}
                    />
                  </div>
                </div>
                <Molecules.Modal
                  setShowModal={setShowModal}
                  showModal={showModal}
                  name={'margin'}
                >
                  <Molecules.ModalWarningandDelete
                    setShowModal={setShowModal}
                    isDelete={false}
                  />
                </Molecules.Modal>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default YourPolls;
