import React from 'react';
import './loader.scss';

const Loader = ({ isCenter }) => {
  return (
    <div className='loader'>
      <div className={isCenter ? 'spinner-border center' : 'spinner-border'}>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  );
};
Loader.defaultProps = {
  usCenter: false,
};

export default Loader;
