import React from 'react';
import * as Atom from '../../atoms';
import * as Molecules from '../index';
import Close from '../../../assests/images/x.svg';
import './customPollInterval.scss';
const CustomPollInterval = (props) => {
  return (
    <div className=' PollTimeSet'>
      <div className=' PollTimeSet__heading'>
        <div className=' PollTimeSet__heading__text'>Poll Length</div>
        <img
          src={Close}
          alt='cl'
          onClick={(e) => props.handleClick(e, 'CLOSE_MODAL')}
        />
      </div>
      <div className=' PollTimeSet__body'>
        <label className=' PollTimeSet__body__label'>Duration of poll</label>
        <Molecules.InputFieldWrapper
          handleBlur={props.handleBlur}
          handleChange={props.handleChange}
          handleFocus={props.handleFocus}
          name='typingCustomPollTime'
          maxLength={3}
          value={props.formValues?.typingCustomPollTime}
          placeHolder={''}
          showCount={false}
          type={'number'}
          inputFieldclassName={
            props.formValues?.typingCustomPollTime > 168 && 'input-field error'
          }
          autoFocus
        />
        <div className=' PollTimeSet__body__infotext'>
          Maximum duration of a poll can only be 168 hrs (1 week).
        </div>
      </div>
      <div className=' PollTimeSet__footer'>
        <Atom.Button
          handleClick={(e) => props.handleClick(e, 'SET_CUSTOM_POLL_TIME')}
          className='w-full'
          isDisabled={
            props.formValues?.typingCustomPollTime > 168 ||
            props.formValues?.typingCustomPollTime < 1
          }
        >
          Set Duration
        </Atom.Button>
      </div>
    </div>
  );
};

export default CustomPollInterval;
