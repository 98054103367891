const devTesting = {
  // API_BASE_URL: "http://dev-testing.classplus.co",
  API_BASE_URL_BATCH:
    "https://store-farhan.dev.classplus.co/v2/notifications/receivers/data",
  API_BASE_URL_FILTER:
    "https://store-farhan.dev.classplus.co/v2/course/filters?screenName=recommend&[TYPEID=typeId]&[OFFSET=offset]&[LIMIT=limit]&[SEARCH=search]",
  API_GRAPH_URL: "http://store-cams-gcp-stage.classplus.co/cams/graph-api",
  TUTOR_TOKEN:
    "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDUzNzMzLCJvcmdJZCI6MSwibmFtZSI6IkNMUCBNQVNURVIgMTk5MyIsImVtYWlsIjoiZW5naW5lZXJpbmdAY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE4MDc2NzY3MzE1IiwidHlwZSI6MywiaWF0IjoxNjI4MjUxODIzLCJleHAiOjE2Mjk5Nzk4MjN9.iarOXACuHpWmzb8KQyKn3MtpT5ebEYDrTGKkflolMDBo8_sl0t3rgeYnkLX-zI11",
  GRAPH_TUTOR_TOKEN:
    "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDUzNzMzLCJvcmdJZCI6MSwibmFtZSI6IkNMUCBNQVNURVIgMTk5MyIsImVtYWlsIjoiZW5naW5lZXJpbmdAY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE4MDc2NzY3MzE1IiwidHlwZSI6MywiaWF0IjoxNjI4MjUxODIzLCJleHAiOjE2Mjk5Nzk4MjN9.iarOXACuHpWmzb8KQyKn3MtpT5ebEYDrTGKkflolMDBo8_sl0t3rgeYnkLX-zI11",

  STUDENT_GRAPH:
    "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDU4MDU2OCwib3JnSWQiOjEsIm5hbWUiOiJhcG9vcnYiLCJlbWFpbCI6InN1YnJhdmV0aUBjbGFzc3BsdXMuY28iLCJtb2JpbGUiOiI5MTcwMzI0Mjc3NTgiLCJ0eXBlIjoxLCJpYXQiOjE2MjgzNTAzODcsImV4cCI6MTYzMDA3ODM4N30.LUEIOUaXChrOC4bC5c4EVjXD1uAMgbe4s37HIFbYrqSsBJtLxkfBtHZt8eze1XJq",
};
// eslint-disable-next-line
const prod = {
  API_BASE_URL: 'https://api.classplusapp.com',
  API_BASE_URL_FILTER:
    'https://api.classplusapp.com/v2/course/filters?screenName=recommend&[TYPEID=typeId]&[OFFSET=offset]&[LIMIT=limit]&[SEARCH=search]',
  API_GRAPH_URL: 'https://api.classplusapp.com/cams/graph-api',
  TUTOR_TOKEN:
    'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6OTAwNTU1LCJvcmdJZCI6MSwibmFtZSI6IlNBSElMIENMQVNTUExVUyBQUk8iLCJlbWFpbCI6InZpcHVsLmFnZ2Fyd2FsQGNsYXNzcGx1cy5jbyIsIm1vYmlsZSI6IjkxOTk5OTkyNDg1NCIsInR5cGUiOjMsImlhdCI6MTYyODYwMjgzNCwiZXhwIjoxNjI5MjA3NjM0fQ.hDXGJzq06NNX8q4a0J41Iy2ybGKk9awyCtFWlCk-gqn3n_bHbhKVZlA9V8Tu8Z8l',
  GRAPH_TUTOR_TOKEN:
    'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6OTAwNTU1LCJvcmdJZCI6MSwibmFtZSI6IlNBSElMIENMQVNTUExVUyBQUk8iLCJlbWFpbCI6InZpcHVsLmFnZ2Fyd2FsQGNsYXNzcGx1cy5jbyIsIm1vYmlsZSI6IjkxOTk5OTkyNDg1NCIsInR5cGUiOjMsImlhdCI6MTYyODYwMjgzNCwiZXhwIjoxNjI5MjA3NjM0fQ.hDXGJzq06NNX8q4a0J41Iy2ybGKk9awyCtFWlCk-gqn3n_bHbhKVZlA9V8Tu8Z8l',
  STUDENT_GRAPH:
    'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MjU2Mzk0MjEsIm9yZ0lkIjoxLCJuYW1lIjoiUHJhdHl1c2giLCJlbWFpbCI6bnVsbCwibW9iaWxlIjoiOTE3MDMyNDI3NzU4IiwidHlwZSI6MSwiaWF0IjoxNjI4NjAyOTM1LCJleHAiOjE2MjkyMDc3MzV9.3uRoeQ0ncW9yn6PE_eDF8igujO9GMm6qwsEvuGNNFim3tuZXd9TvQwhHIj3E5SQw',
};
const preprod = {
  API_BASE_URL: "https://apip-gcp.classplusapp.com",
  API_BASE_URL_FILTER:
    "https://apip-gcp.classplusapp.com/v2/course/filters?screenName=recommend&[TYPEID=typeId]&[OFFSET=offset]&[LIMIT=limit]&[SEARCH=search]",
  API_GRAPH_URL: "https://apip-gcp.classplusapp.com/cams/graph-api",
  TUTOR_TOKEN:
    "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6OTAwNTU1LCJvcmdJZCI6MSwibmFtZSI6IlNBSElMIENMQVNTUExVUyBQUk8iLCJlbWFpbCI6InZpcHVsLmFnZ2Fyd2FsQGNsYXNzcGx1cy5jbyIsIm1vYmlsZSI6IjkxOTk5OTkyNDg1NCIsInR5cGUiOjMsImlhdCI6MTYyODc1ODM1NywiZXhwIjoxNjI5MzYzMTU3fQ.QlIK4hx9rCyiSKzo3NaFyga-W1HAbfVufdmACqtmNQDSFOu5dkLyLFSNMO0JY3i1",
  GRAPH_TUTOR_TOKEN:
    "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6OTAwNTU1LCJvcmdJZCI6MSwibmFtZSI6IlNBSElMIENMQVNTUExVUyBQUk8iLCJlbWFpbCI6InZpcHVsLmFnZ2Fyd2FsQGNsYXNzcGx1cy5jbyIsIm1vYmlsZSI6IjkxOTk5OTkyNDg1NCIsInR5cGUiOjMsImlhdCI6MTYyODc1ODM1NywiZXhwIjoxNjI5MzYzMTU3fQ.QlIK4hx9rCyiSKzo3NaFyga-W1HAbfVufdmACqtmNQDSFOu5dkLyLFSNMO0JY3i1",
  STUDENT_GRAPH:
    "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MTk1MDY4Niwib3JnSWQiOjEsIm5hbWUiOiJBcG9vcnYgR3VwdGEiLCJlbWFpbCI6ImFwb29ydjAzQGNsYXNzcGx1cy5jbyIsIm1vYmlsZSI6IjkxOTAwNTk2MzI2MCIsInR5cGUiOjEsImlhdCI6MTYyODc2MDc5NywiZXhwIjoxNjI5MzY1NTk3fQ.PVJtbOpxHl8rcgyU1_hMrGPsIMaRVvLyvG5yoGRc18QPNqr-dEC7553GCch4Rye8",
};

const URL_OBJECT = (() => {
  console.log(process.env.REACT_APP_ENV);
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return prod;
    case 'preprod':
      return preprod;
    default:
      return devTesting;
  }
})();

export default URL_OBJECT;
