import React, { Suspense, lazy } from 'react';
import * as Molecules from '../../molecules';
const PollCreationForm = lazy(() => import('../../organisms/PollCreationForm'));
const LandingPage = (props) => {
  return (
    <Suspense fallback={<Molecules.LoaderWrapper />}>
      <div className='createpoll__wrapper'>
        <Molecules.NavBar navBar={props.navBar} />
        <PollCreationForm data={props} />
      </div>
    </Suspense>
  );
};

export default LandingPage;
