/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import * as Atoms from '../../atoms';
import * as Molecules from '../../molecules';
// import * as util from '../../../utils';
import './selectbc.scss';
import { getAxios } from '../../../service/axios';
import URL_OBJECT from '../../../service/config';
import { useHistory } from 'react-router-dom';
const appDownloadsEnum = [
  { name: 'All App Downloads', enumValue: 'APP_DOWNLOADS' },
  { name: 'All New App Downloads', enumValue: 'NEW_APP_DOWNLOADS' },
  { name: 'Not in any batch', enumValue: 'NOT_IN_BATCH' },
  { name: 'Not purchased any course', enumValue: 'NOT_IN_COURSE' },
];

const SelectBC = (props) => {
  console.log(props?.location?.state);
  let history = useHistory();
  const [batchValues, setBatchValues] = useState([]);
  const [courseValues, setCourseValues] = useState([]);
  const [isError, setError] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const [selectedCheckboxForBatches, setSelectedCheckboxForBatches] = useState(
    []
  );
  const [selectedCheckboxForCourses, setSelectedCheckboxForCourses] = useState(
    []
  );
  const [
    permanentselectedCheckboxForBatches,
    setPermanentselectedCheckboxForBatches,
  ] = useState([]);
  const [
    permanentselectedCheckboxForCourses,
    setPermanentselectedCheckboxForCourses,
  ] = useState([]);
  const unselectedCheckboxForBatches = useRef([]);
  const unselectedCheckboxForCourses = useRef([]);
  const [appDownloadText, setAppDownloadText] = useState({});
  const [permanentAppDownloadText, setPermanentAppDownloadText] = useState({});
  const [radio, setRadio] = useState('');

  useEffect(() => {
    getAxios(
      URL_OBJECT.API_BASE_URL_FILTER.replace('[TYPEID=typeId]', `typeId=${6}`)
        .replace('[LIMIT=limit]', `limit=${5}`)
        .replace('[OFFSET=offset]', `offset=${0}`)
        .replace('[SEARCH=search]', `search=`)
    )
      .then((res) => {
        setCourseValues([...res.data.list[0].list]);
        if (props?.history?.location?.state?.courseValues) {
          setSelectedCheckboxForCourses([
            ...props?.history?.location?.state?.courseValues,
          ]);
          if (
            props?.history?.location?.state?.unselectedCheckboxListForCourses
          ) {
            unselectedCheckboxForCourses.current = [
              ...props?.history?.location?.state
                ?.unselectedCheckboxListForCourses,
            ];
          }

          if (props?.location?.state?.tempunselectedCheckboxListForCourses) {
            unselectedCheckboxForCourses.current = [
              ...props?.location?.state?.tempunselectedCheckboxListForCourses,
            ];
          }

          setPermanentselectedCheckboxForCourses([
            ...props?.history?.location?.state?.courseValues,
          ]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
      });
    getAxios(
      URL_OBJECT.API_BASE_URL_FILTER.replace('[TYPEID=typeId]', `typeId=${7}`)
        .replace('[LIMIT=limit]', `limit=${5}`)
        .replace('[OFFSET=offset]', `offset=${0}`)
        .replace('[SEARCH=search]', `search=`)
    )
      .then((res) => {
        setBatchValues([...res.data.list[0].list]);
        if (props?.history?.location?.state?.batchValues) {
          setSelectedCheckboxForBatches([
            ...props?.history?.location?.state?.batchValues,
          ]);
          if (
            props?.history?.location?.state?.unselectedCheckboxListForBatches
          ) {
            unselectedCheckboxForBatches.current = [
              ...props?.history?.location?.state
                ?.unselectedCheckboxListForBatches,
            ];
          }
          if (props?.location?.state?.tempunselectedCheckboxListForBatches) {
            unselectedCheckboxForBatches.current = [
              ...props?.location?.state?.tempunselectedCheckboxListForBatches,
            ];
          }

          setPermanentselectedCheckboxForBatches([
            ...props?.history?.location?.state?.batchValues,
          ]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
        setLoader(false);
      });
    if (props?.history?.location?.state?.appDownloadText) {
      setAppDownloadText({
        ...props?.history?.location?.state?.appDownloadText,
      });
      setPermanentAppDownloadText({
        ...props?.history?.location?.state?.appDownloadText,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleAssignToPoll = () => {
    sessionStorage.setItem('IS_COURSES_REMOVED', false);
    sessionStorage.setItem('IS_BATCHES_REMOVED', false);
    sessionStorage.setItem('IS_APP_DOWNLOADS_REMOVED', false);
    sessionStorage.setItem('IS_INITIAL_APP_DOWNLOAD', false);
    console.log(unselectedCheckboxForCourses.current);
    history.push({
      pathname: '/createpolls',
      state: {
        formValues: props?.history?.location?.state?.formValues,
        selectedCheckboxForBatches: permanentselectedCheckboxForBatches,
        selectedCheckboxForCourses: permanentselectedCheckboxForCourses,
        appDownloadText: permanentAppDownloadText,
        SELECT_ALL_COURSE_VALUES:
          props?.location?.state?.SELECT_ALL_COURSE_VALUES,
        SELECT_ALL_BATCH_VALUES:
          props?.location?.state?.SELECT_ALL_BATCH_VALUES,
        unselectedCheckboxListForBatches: unselectedCheckboxForBatches.current,
        unselectedCheckboxListForCourses: unselectedCheckboxForCourses.current,
      },
    });
  };

  const handleChange = (e, type, enumValue) => {
    let { checked, value } = e.target;

    switch (type) {
      case 'APP_DOWNLOADS':
        console.log(checked);
        console.log(permanentAppDownloadText?.enumValue, enumValue);
        if (permanentAppDownloadText?.enumValue === enumValue) {
          setPermanentAppDownloadText({});
        } else {
          setPermanentAppDownloadText({ text: value, enumValue: enumValue });
        }
        if (radio == value) {
          setRadio('');
        } else {
          setRadio(value);
        }
        break;
      case 'BATCH_VALUES':
        let a = [...permanentselectedCheckboxForBatches];
        let index = a.indexOf(value);
        if (checked) {
          if (index === -1) {
            a.push(value);
            unselectedCheckboxForBatches.current =
              unselectedCheckboxForBatches?.current?.filter(
                (id) => id !== value
              );
            // unselectedCheckbox=unselectedCheckbox?.filter(id=>id===value);
          } else {
            a.splice(index);
          }
        } else {
          a = a.filter((id) => id !== value);
          unselectedCheckboxForBatches.current.push(value);
          // unselectedCheckbox.push(value);
          // selectedCheckbox=selectedCheckbox?.filter(id=>id===value);
        }
        setPermanentselectedCheckboxForBatches([...a]);

        break;
      case 'COURSE_VALUES':
        let c = [...permanentselectedCheckboxForCourses];
        let indexc = c.indexOf(value);
        if (checked) {
          if (indexc === -1) {
            c.push(value);
            unselectedCheckboxForCourses.current =
              unselectedCheckboxForCourses?.current?.filter(
                (id) => id !== value
              );

            // unselectedCheckbox=unselectedCheckbox?.filter(id=>id===value);
          } else {
            c.splice(indexc);
          }
        } else {
          c = c.filter((id) => id !== value);
          unselectedCheckboxForCourses.current.push(value.toString());

          // unselectedCheckbox.push(value);
          // selectedCheckbox=selectedCheckbox?.filter(id=>id===value);
        }
        setPermanentselectedCheckboxForCourses([...c]);
        break;

      default:
        break;
    }
  };
  const checkIfButtonIsDisabled = () => {
    let countOfBatch = 0;
    let countOfCourses = 0;
    permanentselectedCheckboxForBatches.forEach((e) => {
      countOfCourses++;
    });
    permanentselectedCheckboxForCourses.forEach((e) => {
      countOfBatch++;
    });
    if (permanentAppDownloadText?.text) {
      return false;
    }
    if (countOfBatch) {
      return false;
    }
    if (countOfCourses) {
      return false;
    }

    return true;
  };
  console.log(
    'states',
    unselectedCheckboxForBatches,
    unselectedCheckboxForCourses,
    permanentselectedCheckboxForBatches,
    permanentselectedCheckboxForCourses,
    selectedCheckboxForBatches,
    selectedCheckboxForCourses
  );
  return (
    <div>
      <>
        <Molecules.NavBar
          navBar={props.navBar}
          data={{
            formValues: props?.history?.location?.state?.formValues,
            selectedCheckboxForBatches:
              props?.history?.location?.state?.initialSavedBatchValues,
            selectedCheckboxForCourses:
              props?.history?.location?.state?.initialSavedCourseValues,
            appDownloadText:
              props?.history?.location?.state?.initialSavedAppDownloadText,
            SELECT_ALL_COURSE_VALUES:
              props?.location?.state?.SELECT_ALL_COURSE_VALUES,
            SELECT_ALL_BATCH_VALUES:
              props?.location?.state?.SELECT_ALL_BATCH_VALUES,
            unselectedCheckboxListForBatches:
              props?.location?.state?.unselectedCheckboxListForBatches,
            unselectedCheckboxListForCourses:
              props?.location?.state?.unselectedCheckboxListForCourses,
          }}
        />
        {isLoader ? (
          <Molecules.LoaderWrapper />
        ) : isError ? (
          <div className='errorDiv'>Please try again later after some time</div>
        ) : (
          <div className='selectbc'>
            <Molecules.CourseCard heading={'APP DOWNLOADS'}>
              {appDownloadsEnum.map((download, i) => (
                <>
                  {/* <Atoms.CheckboxButton
                name={'radio'}
                value={download.name}
                enumValue={download.enumValue}
                label={download.name}
                handleChange={e =>
                  handleChange(e, 'APP_DOWNLOADS', download.enumValue)
                }
                checked={download.name === radio}
                key={`rad${i}8`}
              /> */}
                  <Atoms.RadioButton
                    name={'radio'}
                    value={download.name}
                    enumValue={download.enumValue}
                    label={download.name}
                    handleChange={(e) =>
                      handleChange(e, 'APP_DOWNLOADS', download.enumValue)
                    }
                    isChecked={download.name === permanentAppDownloadText?.text}
                    key={`rad${i}8`}
                  />
                </>
              ))}
            </Molecules.CourseCard>

            <Molecules.CourseCard
              showViewAll={batchValues.length>0?true:false}
              path={'/selectbatch'}
              heading='BATCHES'
              pathStateValues={{
                formValues: props?.history?.location?.state?.formValues,
                permanentselectedCheckboxForCourses,
                permanentselectedCheckboxForBatches,
                permanentAppDownloadText,
                initialSavedBatchValues:
                  props?.history?.location?.state?.initialSavedBatchValues,
                initialSavedCourseValues:
                  props?.history?.location?.state?.initialSavedCourseValues,
                initialSavedAppDownloadText:
                  props?.history?.location?.state?.initialSavedAppDownloadText,
                SELECT_ALL_COURSE_VALUES:
                  props?.location?.state?.SELECT_ALL_COURSE_VALUES,
                SELECT_ALL_BATCH_VALUES:
                  props?.location?.state?.SELECT_ALL_BATCH_VALUES,
                unselectedCheckboxListForBatches:
                  props?.location?.state?.unselectedCheckboxListForBatches,
                unselectedCheckboxListForCourses:
                  props?.location?.state?.unselectedCheckboxListForCourses,
                tempunselectedCheckboxListForBatches:
                  unselectedCheckboxForBatches.current,
                tempunselectedCheckboxListForCourses:
                  unselectedCheckboxForCourses.current,
              }}
            >
           {batchValues.length?  <> {batchValues.map((batchValue, i) => (
                <Atoms.CheckboxButton
                  name={batchValue.name}
                  value={batchValue.id}
                  label={batchValue.name}
                  checked={permanentselectedCheckboxForBatches.includes(
                    batchValue.id.toString()
                  )}
                  handleChange={(e) => handleChange(e, 'BATCH_VALUES')}
                  key={`batc${i}8`}
                />
              ))}
              </>:<div style={{textAlign:'center'}}>No batches created yet{console.log('nothing')}</div>}
            </Molecules.CourseCard>

            <Molecules.CourseCard
              showViewAll={courseValues.length>0?true:false}
              path={'/selectcourse'}
              heading='COURSES'
              pathStateValues={{
                formValues: props?.history?.location?.state?.formValues,
                permanentselectedCheckboxForCourses,
                permanentselectedCheckboxForBatches,
                permanentAppDownloadText,
                initialSavedBatchValues:
                  props?.history?.location?.state?.initialSavedBatchValues,
                initialSavedCourseValues:
                  props?.history?.location?.state?.initialSavedCourseValues,
                initialSavedAppDownloadText:
                  props?.history?.location?.state?.initialSavedAppDownloadText,
                SELECT_ALL_COURSE_VALUES:
                  props?.location?.state?.SELECT_ALL_COURSE_VALUES,
                SELECT_ALL_BATCH_VALUES:
                  props?.location?.state?.SELECT_ALL_BATCH_VALUES,
                unselectedCheckboxListForBatches:
                  props?.location?.state?.unselectedCheckboxListForBatches,
                unselectedCheckboxListForCourses:
                  props?.location?.state?.unselectedCheckboxListForCourses,
                tempunselectedCheckboxListForBatches:
                  unselectedCheckboxForBatches.current,
                tempunselectedCheckboxListForCourses:
                  unselectedCheckboxForCourses.current,
              }}
            >
             {courseValues.length? <> {courseValues.map((courseValue, i) => (
                <Atoms.CheckboxButton
                  name={courseValue.name}
                  value={courseValue.id}
                  label={courseValue.name}
                  checked={permanentselectedCheckboxForCourses.includes(
                    courseValue.id.toString()
                  )}
                  handleChange={(e) => handleChange(e, 'COURSE_VALUES')}
                  key={`cours${i}`}
                />
              ))}</>:<div style={{textAlign:'center'}}>No batches created yet{console.log('nothing')}</div>}
            </Molecules.CourseCard>
            <div
              className='BC_Selection_Wrapper bottom'
              style={{
                width: document.querySelector('.Navbar')?.clientWidth + 'px',
              }}
            >
              <Atoms.Button
                handleClick={() => handleAssignToPoll()}
                isDisabled={checkIfButtonIsDisabled()}
              >
                Assign To Poll
              </Atoms.Button>
            </div>
          </div>
        )}{' '}
      </>
    </div>
  );
};

export default SelectBC;
