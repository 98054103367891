import React, { useState } from 'react';
import * as Atoms from '../../atoms';
import './searchbox.scss';
import Search from '../../../assests/images/search.png';

const SearchBox = ({
  handleBlur,
  handleChange,
  maxLength,
  placeHolder,
  value,
  name,
  showCount,
  inputFieldclassName,
  handleFocus,
}) => {
  const [focus, setFocus] = useState(false);
  const handleFocuss = () => {
    setFocus(true);
  };
  const handleBlurr = (e) => {
    setFocus(false);
  };
  return (
    <div className='SearchBox__Wrapper'>
      <div className={focus ? 'SearchBox focus' : 'SearchBox'}>
        <div className='SearchBox__Search'>
          <img src={Search} alt='s' />
        </div>
        <Atoms.InputField
          handleBlur={(e) => handleBlurr(e)}
          handleChange={handleChange}
          value={value}
          name={name}
          placeHolder={placeHolder}
          inputFieldclassName={inputFieldclassName}
          handleFocus={handleFocuss}
        />
      </div>
    </div>
  );
};

export default SearchBox;
