const studentPollQuery = `
#POLLVOTE
mutation ($token: String!, $pollId: String!, $choiceId: Int!) {
   mutationWithAuth(token:$token) {
    update{
      poll(id:$pollId){
        vote(choiceId:$choiceId){
          id
        name
        choices{
          id
          choice
          voteInPercentage
          votes
          myVote
        },
        pollLength
        pollLengthText
        appDownload
        isExpired
        }
        
        
        
      }
    }
  }
}
`;
export default studentPollQuery;
