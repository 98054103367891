import React from 'react';
import './checkbox.scss';
const Checkbox = ({ label, handleChange, name, checked, value }) => {
  return (
    // <div className='checkbox'>
    //     <label htmlFor={name}>{label}</label>
    //     <input type='checkbox' onChange={handleChange} name={name} id={name}/>
    // </div>

    <label className='checkbox'>
      <span className='radio__label'>{label}</span>
      <span className={'checkbox__input'}>
        <input
          type='checkbox'
          name={name}
          value={value}
          onChange={handleChange}
          checked={checked}
        />
        <span className='checkbox__control'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            aria-hidden='true'
            focusable='false'
          >
            <path
              fill='none'
              stroke='currentColor'
              strokeWidth='3'
              d='M1.73 12.91l6.37 6.37L22.79 4.59'
            />
          </svg>
        </span>
      </span>
    </label>
  );
};
Checkbox.defaultProps = {
  checked: false,
  name: 'test',
  label: 'test',
  handleChange: () => {},
};

export default Checkbox;
