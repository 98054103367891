/* eslint-disable array-callback-return */
import { getURLPARAMS } from '../utils/helper';
import URL_OBJECT from './config';

const sentPollApiFormatter = (
  query,
  formValues,
  appDownloadType,
  selectedBatches,
  selectedCourses,
  unselectedBatches,
  unSelectedCourses,
  isAllBatchesSelected,
  isAllCoursesSelected
) => {
  const checkPollLength = () => {
    if (formValues?.customPollTime) {
      return parseInt(formValues?.customPollTime, 10);
    } else {
      if (formValues?.pollLength?.includes('day')) {
        return formValues?.pollLength?.match(/\d/g) * 24;
      } else {
        // eslint-disable-next-line
        return parseInt(formValues?.pollLength?.replace(/[^0-9\.]+/g, ''), 10);
      }
    }
  };
  const formatValues = (type) => {
    let selected = [];
    let isAllSelected = false;
    let deselected = [];
    if (type === 'BATCHES') {
      isAllSelected = isAllBatchesSelected;
      if (isAllSelected) {
        deselected = unselectedBatches.map(Number);
      } else {
        selected = selectedBatches.map(Number);
      }
    } else {
      isAllSelected = isAllCoursesSelected;
      if (isAllSelected) {
        deselected = unSelectedCourses.map(Number);
      } else {
        selected = selectedCourses.map(Number);
      }
      // selected.push(52097);
    }

    return {
      selected,
      deselected,
      isAllSelected,
    };
  };
  const choicesValues = () => {
    let arr = [];

    [1, 2, 3, 4].map((e) => {
      if (formValues[`option${e}`]) {
        arr.push(formValues[`option${e}`]);
      }
    });

    return arr;
  };
  return {
    query: query,
    variables: {
      token:
        getURLPARAMS().token || JSON.parse(sessionStorage.getItem('token')),

      name: formValues.question,
      pollLength: checkPollLength(),
      choices: choicesValues(),
      courses: formatValues(),
      batches: formatValues('BATCHES'),
      appDownload: appDownloadType?.enumValue || null,
    },
  };
};
const getPollDetailsFormatter = (query, variables = {}) => {
  variables.token =
    getURLPARAMS().token || JSON.parse(sessionStorage.getItem('token'));

  return {
    query: query,
    variables: variables,
  };
};
const getPollDetailsStudentFormatter = (query, variables = {}) => {
  variables.token =
    getURLPARAMS().token || JSON.parse(sessionStorage.getItem('token'));
  return {
    query: query,
    variables: variables,
  };
};
const deletePollFormatter = (query, variables = {}) => {
  variables.token =
    getURLPARAMS().token || JSON.parse(sessionStorage.getItem('token'));
  return {
    query: query,
    variables: variables,
  };
};
const postStudentPollFormatter = (query, variables = {}) => {
  variables.token =
    getURLPARAMS().token || JSON.parse(sessionStorage.getItem('token'));
  return {
    query: query,
    variables: variables,
  };
};
export {
  sentPollApiFormatter,
  getPollDetailsFormatter,
  deletePollFormatter,
  postStudentPollFormatter,
  getPollDetailsStudentFormatter,
};
