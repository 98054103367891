import React from 'react';
import { Carousel } from '../../organisms';

const StudentPoll = () => {
  return (
    <>
      <Carousel />
    </>
  );
};

export default StudentPoll;
