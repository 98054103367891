const fixtures = {
  navBar: [
    { text: 'Your Polls', currentPath: '/yourpolls', toPath: '/yourpolls' },
    { text: 'Create Polls', currentPath: '/createpolls', toPath: '/yourpolls' },
    {
      text: 'Select Batch/Course',
      currentPath: '/selectb_c',
      toPath: '/createpolls',
    },
    {
      text: 'Select Batch',
      currentPath: '/selectbatch',
      toPath: '/selectb_c',
    },
    {
      text: 'Select Course',
      currentPath: '/selectcourse',
      toPath: '/selectb_c',
    },
    { text: 'Poll Details', currentPath: '/polldetails', toPath: '/yourpolls' },
    {
      text: 'Poll Details',
      currentPath: '/singlePollDetails',
      toPath: '/yourpolls',
    },
  ],
};

export default fixtures;
