import axios from 'axios';
import { getURLPARAMS } from '../utils/helper';
import URL_OBJECT from './config';
const postAxios = (url, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload)
      .then((response) => {
        let { errors } = response.data;
        if (errors)
          return reject({ message: errors[0].message, data: response.data });
        return resolve(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
const getAxios = (url) => {
  console.log(getURLPARAMS().token);
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          'x-access-token':
            getURLPARAMS().token || JSON.parse(sessionStorage.getItem('token')),
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export { postAxios, getAxios };
