import React from 'react';
import './radiobutton.scss';

const RadioButton = ({
  value,
  name,
  label,
  handleChange,
  isChecked,
  enumValue,
}) => {
  return (
    <label className='radio radio-before'>
      <span className='radio__label'>{label}</span>
      <span className='radio__input'>
        <input
          type='radio'
          name={name}
          onClick={handleChange}
          value={value}
          checked={isChecked}
          enumvalue={enumValue}
        />
        <span className='radio__control'></span>
      </span>
    </label>
  );
};
RadioButton.defaultProps = {
  isChecked: false,
};
export default RadioButton;
