/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, Suspense, lazy } from 'react';
import * as Molecules from '../molecules';
import * as Atom from '../atoms';
import './pollcreationform.scss';
import { useHistory } from 'react-router-dom';
import { postAxios } from '../../service/axios';
import sendPollDetails from '../../graphql/sendPollDetails';
import { sentPollApiFormatter } from '../../service/apiFormatter';
import Shimmer from '../atoms/Shimmer/Shimmer';
import URL_OBJECT from '../../service/config';
const InputFieldWrapper = lazy(() =>
  import('../molecules/InputFieldWrapper/InputFieldWrapper')
);
const pollData = [
  { name: 'option1', maxLength: 25, placeHolder: 'Choice 1', index: 1 },
  { name: 'option2', maxLength: 25, placeHolder: 'Choice 2', index: 2 },
  {
    name: 'option3',
    maxLength: 25,
    placeHolder: 'Choice 3 (Optional)',
    index: 3,
  },
  {
    name: 'option4',
    maxLength: 25,
    placeHolder: 'Choice 4 (Optional)',
    index: 4,
  },
];
const pollIntervalTime = [
  { value: '12hrs' },
  { value: '1 day' },
  { value: '2 day' },
];
const PollCreationForm = (props) => {
  console.log(props?.data?.location?.state);
  let history = useHistory();
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectAllBatches, setSelectAllBatches] = useState(false);
  const [selectAllCourses, setSelectAllCourses] = useState(false);
  const [selectedCount, setSelectedCount] = useState({
    batchCount: 0,
    courseCount: 0,
  });
  const [formValues, setFormValues] = useState({
    customPollTime: '',
    typingCustomPollTime: '',
    currentChoiceLength: 2,
  });
  const [appDownloadText, setAppDownloadText] = useState({
    text: 'All App Downloads',
    enumValue: 'APP_DOWNLOADS',
  });
  const [selectedCheckboxForBatches, setSelectedCheckboxForBatches] = useState(
    []
  );
  const [selectedCheckboxForCourses, setSelectedCheckboxForCourses] = useState(
    []
  );

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'typingCustomPollTime') {
      if (/^[0-9\b]+$/.test(value)) {
      } else {
        value = '';
      }
      console.log(value, value.length);
      if (value.length > 3) {
        value = value.slice(0, 3);
      }
    }
    if (name !== 'question') {
      if (value.length > 25) {
        value = value.slice(0, 25);
      }
    }
    if (name == 'question') {
      if (value.length > 140) {
        console.log('value >140');
        value = value.slice(0, 140);
      }
    }
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (!JSON.parse(sessionStorage.getItem('IS_INITIAL_APP_DOWNLOAD'))) {
      setAppDownloadText({});
    }
    if (props?.data?.history?.location?.state?.formValues) {
      let form = { ...props?.data?.history?.location?.state?.formValues };
      if (!form.option4) {
        if (!form.option3) {
          form.currentChoiceLength =
            form.currentChoiceLength <= 2
              ? form.currentChoiceLength
              : form.currentChoiceLength - 2;
        } else {
          form.currentChoiceLength =
            form.currentChoiceLength <= 3
              ? form.currentChoiceLength
              : form.currentChoiceLength - 1;
        }
      } else if (!form.option3) {
        form.currentChoiceLength =
          form.currentChoiceLength <= 2
            ? form.currentChoiceLength
            : form.currentChoiceLength - 1;
      }

      setFormValues({ ...form });
    }
    let selectedbatchCount = 0;
    let selectedcourseCount = 0;

    if (props?.data?.location?.state?.selectedCheckboxForBatches) {
      if (!JSON.parse(sessionStorage.getItem('IS_BATCHES_REMOVED'))) {
        selectedbatchCount =
          props?.data?.location?.state?.selectedCheckboxForBatches.length;

        setSelectedCheckboxForBatches([
          ...props?.data?.location?.state?.selectedCheckboxForBatches,
        ]);
      }
    }
    if (props?.data?.location?.state?.selectedCheckboxForCourses) {
      if (!JSON.parse(sessionStorage.getItem('IS_COURSES_REMOVED'))) {
        selectedcourseCount =
          props?.data?.location?.state?.selectedCheckboxForCourses.length;

        setSelectedCheckboxForCourses([
          ...props?.data?.location?.state?.selectedCheckboxForCourses,
        ]);
      }
    }
    if (props?.data?.location?.state?.appDownloadText) {
      if (!JSON.parse(sessionStorage.getItem('IS_APP_DOWNLOADS_REMOVED'))) {
        setAppDownloadText(props?.data?.location?.state?.appDownloadText);
      }
    }
    if (props?.data?.location?.state?.SELECT_ALL_COURSE_VALUES) {
      if (!JSON.parse(sessionStorage.getItem('IS_COURSES_REMOVED'))) {
        setSelectAllCourses(true);
      }
    }
    if (props?.data?.location?.state?.SELECT_ALL_BATCH_VALUES) {
      if (!JSON.parse(sessionStorage.getItem('IS_BATCHES_REMOVED'))) {
        setSelectAllBatches(true);
      }
    }
    setSelectedCount((selectedCount) => ({
      ...selectedCount,
      batchCount: selectedbatchCount,
      courseCount: selectedcourseCount,
    }));
    // eslint-disable-next-line
  }, []);

  const handleBlur = (e) => {};
  const handleFocus = (e) => {};
  const handleClick = (e, type) => {
    switch (type) {
      case 'ADD_INPUT_FIELDS':
        if (formValues.currentChoiceLength < 5) {
          let formVal = { ...formValues };
          formVal.currentChoiceLength = formVal.currentChoiceLength + 1;

          setFormValues({ ...formVal });
        }
        break;
      case 'OPEN_BATCH_SELECTION_PAGE':
        history.push('/selectb_c');
        break;
      case 'SHOW_MODAL':
        setShowModal(true);
        break;
      case 'CLOSE_MODAL':
        setShowModal(false);
        break;
      case 'SET_CUSTOM_POLL_TIME':
        setFormValues((formValues) => ({
          ...formValues,
          pollLength: '',
          customPollTime:
            formValues.typingCustomPollTime > 0
              ? formValues.typingCustomPollTime
              : '',
        }));
        setShowModal(false);
        break;
      case 'SET_POLL_TIME':
        setFormValues((formValues) => ({
          ...formValues,
          pollLength: e.target.value,
          customPollTime: '',
        }));
        break;
      default:
        break;
    }
  };

  const handleCreatePoll = () => {
    setLoading(true);

    postAxios(
      URL_OBJECT.API_GRAPH_URL,
      sentPollApiFormatter(
        sendPollDetails,
        formValues,
        appDownloadText,
        selectedCheckboxForBatches,
        selectedCheckboxForCourses,
        props?.data?.location?.state?.unselectedCheckboxListForBatches,
        props?.data?.location?.state?.unselectedCheckboxListForCourses,
        selectAllBatches,
        selectAllCourses
      )
    )
      .then((res) => {
        setLoading(false);

        history.push('/yourpolls');
      })
      .catch((err) => {
        setError(true);
        // setShowModal(true);
      });
  };

  const removeBatches = () => {
    setSelectedCount((selectedCount) => ({
      ...selectedCount,
      batchCount: 0,
    }));
    setSelectedCheckboxForBatches([]);
    setSelectAllBatches(false);
    sessionStorage.setItem('IS_BATCHES_REMOVED', true);
  };
  const removeCourses = () => {
    setSelectedCount((selectedCount) => ({
      ...selectedCount,
      courseCount: 0,
    }));
    setSelectedCheckboxForCourses([]);
    setSelectAllCourses(false);
    sessionStorage.setItem('IS_COURSES_REMOVED', true);
  };
  const removeAppDownloads = () => {
    setAppDownloadText({});
    sessionStorage.setItem('IS_INITIAL_APP_DOWNLOAD', false);
    sessionStorage.setItem('IS_APP_DOWNLOADS_REMOVED', true);
  };
  const checkIfSubmitButtonIsDisabled = () => {
    const isAtleastOneOfTheCheckBoxChipAvailable = () => {
      if (selectedCount.courseCount > 0) return true;
      if (selectedCount.batchCount > 0) return true;
      return false;
    };
    if (
      formValues?.question &&
      formValues?.option1 &&
      formValues?.option2 &&
      (formValues.customPollTime || formValues.pollLength) &&
      (appDownloadText?.text || isAtleastOneOfTheCheckBoxChipAvailable())
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {}, [selectedCheckboxForBatches, selectedCheckboxForCourses]);
  return (
    <>
      {isError ? (
        <div className='errorDiv'>Please try again after some time</div>
      ) : (
        <>
          <div className='question-form'>
            <h3>Add Question & Choices</h3>
            <Suspense fallback={<Shimmer />}>
              <InputFieldWrapper
                handleBlur={handleBlur}
                handleChange={handleChange}
                name='question'
                maxLength={140}
                value={formValues?.question}
                placeHolder={'Type your question'}
                handleFocus={handleFocus}
              />
            </Suspense>
            <Suspense fallback={<Shimmer />}>
              {pollData.map((poll, index) => (
                <>
                  {poll.index <= formValues.currentChoiceLength ? (
                    <InputFieldWrapper
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      name={poll.name}
                      maxLength={poll.maxLength}
                      value={formValues?.[poll.name]}
                      placeHolder={poll.placeHolder}
                      handleFocus={handleFocus}
                      key={`iunsn${index}input`}
                    />
                  ) : null}
                </>
              ))}
            </Suspense>
            {formValues.currentChoiceLength === 4 ? null : (
              <Atom.Button
                className='add w-full'
                handleClick={(e) => handleClick(e, 'ADD_INPUT_FIELDS')}
              >
                + Add New
              </Atom.Button>
            )}
          </div>
          <div className='poll-length'>
            <h3>Poll Length</h3>
            <div className='poll-length__buttonWrapper'>
              {pollIntervalTime.map((time) => (
                <Atom.Button
                  handleClick={(e) => handleClick(e, 'SET_POLL_TIME')}
                  className={
                    formValues?.pollLength === time.value
                      ? 'polllength blue'
                      : 'polllength'
                  }
                  value={time.value}
                >
                  {time.value}
                </Atom.Button>
              ))}
              <Atom.Button
                handleClick={(e) => handleClick(e, 'SHOW_MODAL')}
                className={
                  formValues?.customPollTime ? 'polllength blue' : 'polllength'
                }
              >
                {formValues?.customPollTime > 0
                  ? formValues.customPollTime + ' hrs'
                  : 'Custom'}
              </Atom.Button>
            </div>
          </div>
          <div className='assign'>
            <h3>Assign to Batch/Course (Optional)</h3>
            <Atom.Button className='polllength w-full'>
              <Molecules.ChipContainer
                data={{ selectedCount }}
                removeCourses={removeCourses}
                removeBatches={removeBatches}
                removeAppDownloads={removeAppDownloads}
                pathStateValues={{
                  formValues,
                  selectedCheckboxForBatches,
                  selectedCheckboxForCourses,
                  appDownloadText,
                  SELECT_ALL_COURSE_VALUES: selectAllCourses,
                  SELECT_ALL_BATCH_VALUES: selectAllBatches,
                  unselectedCheckboxListForBatches:
                    props?.data?.location?.state
                      ?.unselectedCheckboxListForBatches,
                  unselectedCheckboxListForCourses:
                    props?.data?.location?.state
                      ?.unselectedCheckboxListForCourses,
                }}
              />
            </Atom.Button>
          </div>
          <Molecules.Modal showModal={showModal} name={'bottom'}>
            <Molecules.CustomPollInterval
              handleClick={handleClick}
              formValues={formValues}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleFocus={handleFocus}
            />
          </Molecules.Modal>
          <div
            className='BC_Selection_Wrapper bottom'
            style={{
              width: document.querySelector('.Navbar')?.clientWidth + 'px',
            }}
          >
            <Atom.Button
              handleClick={() => handleCreatePoll()}
              isDisabled={checkIfSubmitButtonIsDisabled() || isLoading}
            >
              {isLoading ? <Atom.Loader /> : 'Send Poll'}
            </Atom.Button>
          </div>
        </>
      )}
    </>
  );
};

export default PollCreationForm;
