const sendPollDetails = `
mutation ($token: String!, $name:String!, $choices: [String]!,$appDownload: EnumPollAppDownloadType, $courses: PollFilter, $batches: PollFilter, $pollLength: Int!) {
    mutationWithAuth(token:$token) {
     fabricate{
       poll(name: $name, choices: $choices, pollLength: $pollLength, courses: $courses, batches: $batches, appDownload: $appDownload){
         id
         name
         choices{
           id
           choice
           voteInPercentage
           votes
           myVote
         },
         pollLength
         pollLengthText
         appDownload
         courseIds
         batchIds
       }
     }
   }
 }
`;
export default sendPollDetails;
