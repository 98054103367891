import React from 'react'

const Shimmer = () => {
    return (
        <div className="card br">
        <div className="wrapper">
          
           <div className="comment br animate w80"></div>
           <div className="comment br animate"></div>
           <div className="comment br animate"></div>
        </div>
     </div>
    )
}

export default Shimmer
