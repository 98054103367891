import React from 'react';
import './modalwarningandelete.scss';
import Warning from '../../../assests/images/warning.svg';
import ErrorImg from '../../../assests/images/Error.svg';
import Close from '../../../assests/images/x.svg';
const CannotDelete = () => {
  return (
    <div>
      Hi there! Sorry, you cannot<b> DELETE</b> any other{' '}
      <b>Pro Teacher’s poll.</b>
    </div>
  );
};
const ModalWarningandDelete = ({
  setShowModal,
  isDelete,
  onDelete,
  isAllowedToDelete,
  setisAllowedToDelete,
}) => {
  const handleClick = () => {
    if (isDelete) {
      if (isAllowedToDelete) {
        onDelete();
      }
    }
    setShowModal(false);
  };
  return (
    <div className='ModalWrapper'>
      <div className='close'>
        <img
          src={Close}
          alt='c'
          onClick={() => {
            setShowModal(false);
          }}
        />
      </div>

      <div className='MaxLimit'>
        <div className='MaxLimit__Logo'>
          {!isDelete ? (
            <img src={Warning} alt='w' />
          ) : (
            <img src={ErrorImg} alt='e' />
          )}
        </div>
        <div className='MaxLimit--Bold'>
          {isDelete
            ? isAllowedToDelete
              ? 'Remove Poll'
              : 'Cannot Delete'
            : 'You have reached max limit'}
        </div>
        <div className='MaxLimit__Description'>
          {isDelete ? (
            isAllowedToDelete ? (
              'You are about to remove this poll. Are you sure you want to proceed?'
            ) : (
              <CannotDelete />
            )
          ) : (
            'You can create only 3 active polls at a single time. Delete an ongoing poll to create a new one.'
          )}{' '}
        </div>
        <div className='MaxLimit__okay' onClick={() => handleClick()}>
          {isDelete ? (isAllowedToDelete ? 'YES, REMOVE' : 'OKAY') : 'OKAY'}
        </div>
      </div>
    </div>
  );
};
export default ModalWarningandDelete;
