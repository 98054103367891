import React from 'react';
import './chipContainer.scss';
import Plus from '../../../assests/images/pluscircle.svg';
import { useHistory } from 'react-router';
import * as Atoms from '../../atoms';
const ChipContainer = (props) => {
  console.log(props);
  let history = useHistory();
  return (
    <div className='Chip__Container'>
      <div className='Chip__Container__Wrapper'>
        {props?.pathStateValues?.appDownloadText?.text ? (
          <Atoms.Chip
            text={props?.pathStateValues?.appDownloadText?.text}
            onClick={props?.removeAppDownloads}
          />
        ) : null}
        {props?.data?.selectedCount?.batchCount ? (
          <Atoms.Chip
            text={
              props?.pathStateValues?.SELECT_ALL_BATCH_VALUES
                ? 'All Batches Selected'
                : props?.data?.selectedCount?.batchCount + ' Batches'
            }
            onClick={props?.removeBatches}
          />
        ) : null}
        {props?.data?.selectedCount?.courseCount ? (
          <Atoms.Chip
            text={
              props?.pathStateValues?.SELECT_ALL_COURSE_VALUES
                ? 'All Courses Selected'
                : props?.data?.selectedCount?.courseCount + ' Courses'
            }
            onClick={props?.removeCourses}
          />
        ) : null}
      </div>
      <div className='Chip__Container__Add'>
        <img
          src={Plus}
          alt='add'
          onClick={() => {
            history.push({
              pathname: '/selectb_c',
              state: {
                formValues: props?.pathStateValues?.formValues,
                courseValues:
                  props?.pathStateValues?.selectedCheckboxForCourses,
                batchValues: props?.pathStateValues?.selectedCheckboxForBatches,
                appDownloadText: props?.pathStateValues?.appDownloadText,
                comingFrom: 'pollcreationform',
                initialSavedCourseValues:
                  props?.pathStateValues?.selectedCheckboxForCourses,
                initialSavedBatchValues:
                  props?.pathStateValues?.selectedCheckboxForBatches,
                initialSavedAppDownloadText:
                  props?.pathStateValues?.appDownloadText,
                SELECT_ALL_COURSE_VALUES:
                  props?.pathStateValues?.SELECT_ALL_COURSE_VALUES,
                SELECT_ALL_BATCH_VALUES:
                  props?.pathStateValues?.SELECT_ALL_BATCH_VALUES,
                unselectedCheckboxListForBatches:
                  props?.pathStateValues?.unselectedCheckboxListForBatches,
                unselectedCheckboxListForCourses:
                  props?.pathStateValues?.unselectedCheckboxListForCourses,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default ChipContainer;
