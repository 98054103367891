import React from 'react';
import { Loader } from '../../atoms';
import './loaderwrapper.scss';

const LoaderWrapper = () => {
  return (
    <div className='loader-wrapper'>
      <Loader />
    </div>
  );
};

export default LoaderWrapper;
