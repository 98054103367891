import React, { useEffect, useState, Suspense, lazy } from 'react';
// import Slider from 'react-slick';
import getPollDetails from '../../../graphql/getPollDetails';
import {
  getPollDetailsStudentFormatter,
  postStudentPollFormatter,
} from '../../../service/apiFormatter';
import { postAxios } from '../../../service/axios';
import * as Atoms from '../../atoms';
import * as Molecules from '../../molecules';
import Selected from '../../../assests/images/selected.svg';
import './carousel.scss';
import studentPollQuery from '../../../graphql/studentPollQuery';
import URL_OBJECT from '../../../service/config';
const Slider = lazy(() => import('react-slick'));
const questionHeight=100;
let optionsHeight=0;
const singleOptionHeight=50;
const miscellaneousHeight=110;
let totalHeight=0;
let currentPollHeight=1;
const SimpleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => callSetSlideIndex(current),
  };
  const callSetSlideIndex = (current) => {
    setSlideIndex(current);
  };
  const changeSliderClassname=()=>{
    switch(totalHeight){
      case 410:
        return 'count4';
       
          case 360:
            return 'count3';
            case 310:
              return 'count2';
              case 260:
                return 'count1';
         default:
           return 'count3'
    }
  }
  const [slideIndex, setSlideIndex] = useState(0);
  const [polls, setPolls] = useState({});
  const [apiFetching, setApiFetching] = useState(true);
  const [isError, setError] = useState(false);
  // eslint-disable-next-line
  const [selectedOptions, setSelectedOptions] = useState({});
  useEffect(() => {
    getStudentPollData();
  }, []);
  const getStudentPollData = () => {
    postAxios(
      URL_OBJECT.API_GRAPH_URL,
      getPollDetailsStudentFormatter(getPollDetails)
    )
      .then((res) => {
        // setPolls([...res?.data?.withAuth?.user?.polls])
        let unExpiredPolls = res?.withAuth?.user?.polls?.filter(
          (poll) => poll.isExpired === false
        );
        let alreadyVoted = [];
        console.log(alreadyVoted);
        let notVoted = [];
        alreadyVoted = unExpiredPolls.filter((d) => d.alreadyVoted);
        notVoted = unExpiredPolls.filter((d) => !d.alreadyVoted);
        console.log(notVoted);
        res.withAuth.user.polls = [...notVoted, ...alreadyVoted];
        console.log(res.withAuth.user.polls);
        // res.withAuth.user.polls = unExpiredPolls;
        // console.log(res.withAuth.user.polls,'cehck this polls',currentPollHeight)
        (res.withAuth.user.polls || []).forEach(e=>{
          console.log(e.choices,'check this choice')
          currentPollHeight=singleOptionHeight* e.choices.length;
          if (currentPollHeight > optionsHeight) optionsHeight = currentPollHeight;
          currentPollHeight=1;
        })
         totalHeight=questionHeight+optionsHeight+miscellaneousHeight;
        console.log(totalHeight,'tt height')
        console.log(document.querySelector('.slick-slider'),'this is style')
        // document.querySelector('.slick-slider').style.minHeight=totalHeight+'px';
        console.log(optionsHeight,'this is the current poll height');
        setPolls({ ...res });
        setApiFetching(false);
      })
      .catch((err) => {
        console.log(err)
        setApiFetching(false);
        setError(true);
      });
  };

  const handleSelection = (pollId, choiceId) => {
    setApiFetching(true);
    setSelectedOptions((selectedOptions) => ({
      ...selectedOptions,
      [pollId]: {
        [pollId]: choiceId,
        isChecked: true,
      },
    }));
    let variables = { pollId: pollId, choiceId: choiceId };
    postAxios(
      URL_OBJECT.API_GRAPH_URL,
      postStudentPollFormatter(studentPollQuery, variables)
    ).then((res) => {
      getStudentPollData();
    });
  };
  const setStyles = (c) => {
    
    return {
      width:'30%'// c.voteInPercentage + '%',
    };
  };
  console.log(slideIndex)
  return (
    <div
      className={
        polls?.withAuth?.user?.polls.length > 0
          ? 'Carousel__Wrapper '+changeSliderClassname()
          : 'Carousel__Wrapper__empty '+changeSliderClassname()
      }
    >
      {isError ? (
        <div className='errorDiv'>Nothing to display</div>
      ) : (
        <Suspense fallback={<Molecules.LoaderWrapper />}>
          <div className='Carousel__Wrapper__Container'>
            {apiFetching ? (
              <Molecules.LoaderWrapper />
            ) : (
              <>
                {polls?.withAuth?.user?.polls.length > 0 ? (
                  <>
                   <h3 className='livePollsHeading'>Live Polls</h3>
                  <Slider {...settings} className={changeSliderClassname()}>
                    {/* {document.querySelector('.slick-slider').style.minHeight=totalHeight+'px'} */}
                    {polls?.withAuth?.user?.polls.map((poll, index) => (
                      <>
                        {poll.isExpired ? null : (
                          <>
                           
                              <div
                                className='Studentpoll__Card unselected'
                                key={`dadsd${index}ad`}
                              >
                                <div className='Studentpoll__Card__Heading'>
                                  {poll.name}
                                </div>

                                <>
                                  {poll.choices.map((c, i) => (
                                    <>
                                      {!poll.alreadyVoted ? (
                                        <Atoms.Button
                                          handleClick={() => {
                                            handleSelection(poll.id, c.id);
                                          }}
                                          className='Student'
                                          key={`asd${c.id}`}
                                        >
                                          {c.choice}
                                        </Atoms.Button>
                                      ) : (
                                        <div className='StatisticsCard__element'>
                                          <div className='StatisticsCard__element__text'>
                                            <div
                                                className={`StatisticsCard__element__text__graph ${slideIndex === index &&'show'} student`}
                                                style={slideIndex===index?setStyles(c):null}
                                                // style={{ width: '100%' }}
                                            >
                                              <div className='StatisticsCard__element__text--text'>
                                                {c.choice}{' '}
                                                {c.myVote && (
                                                  <span>
                                                    <img
                                                      src={Selected}
                                                      alt='c'
                                                      style={{
                                                        paddingLeft: '5px',
                                                      }}
                                                    />
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className='StatisticsCard__element__completed'>
                                            {c.voteInPercentage + '%'}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </>
                                <div className='Studentpoll__Card__Time'>
                                  {poll.pollLengthText}
                                </div>
                              </div>
                            
                          </>
                        )}
                      </>
                    ))}
                  </Slider>
                  </>
                ) : (
                  <div className='nopolls'></div>
                )}
              </>
            )}
          </div>
        </Suspense>
      )}
    </div>
  );
};
export default SimpleSlider;
