import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import fixtures from "./assests/fixtures";
import * as Pages from "./components/pages";

const Routes = () => {
	return (
    <>
      <Switch>
        <Route
          path="/yourpolls"
          render={(props) => (
            <Pages.YourPolls {...{ ...props, navBar: fixtures.navBar[0] }} />
          )}
          exact
        />
        <Route
          path="/createpolls"
          render={(props) => (
            <Pages.CreatePoll {...{ ...props, navBar: fixtures.navBar[1] }} />
          )}
          exact
        />
        <Route
          path="/selectb_c"
          render={(props) => (
            <Pages.SelectBC {...{ ...props, navBar: fixtures.navBar[2] }} />
          )}
          exact
        />
        <Route
          path="/selectbatch"
          render={(props) => (
            <Pages.SelectBatch {...{ ...props, navBar: fixtures.navBar[3] }} />
          )}
          exact
        />
        <Route
          path="/selectcourse"
          render={(props) => (
            <Pages.SelectCourse {...{ ...props, navBar: fixtures.navBar[4] }} />
          )}
          exact
        />
        <Route
          path="/polldetails"
          render={(props) => (
            <Pages.SinglePollStatistic
              {...{ ...props, navBar: fixtures.navBar[5] }}
            />
          )}
          exact
        />
        {/* <Route path='/singlePollDetails/:id' render={(props) => <Pages.SinglePollStatistic {...{...props,navBar:fixtures.navBar[6]}}/>} exact/> */}
        <Route
          path="/studentpoll"
          render={(props) => (
            <Pages.StudentPoll {...{ ...props, navBar: fixtures.navBar[5] }} />
          )}
          exact
        />

        <Redirect from="**" to="/yourpolls" />
      </Switch>
    </>
  );
};

export default Routes;
