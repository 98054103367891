const getPollDetails = `query ($token: String!,$limit: Int, $offset: Int) {
  withAuth(token:$token) {
   user{
     polls(limit:$limit,offset:$offset,returnPaginationMetaData:true){
       id
       name
       choices{
         id
         choice
         voteInPercentage
         votes
         myVote
       },
       pollLength
       pollLengthText
       appDownload
       isExpired
       totalVotes
       alreadyVoted
       
     }
   }
   
 }
}
`;
export default getPollDetails;
